import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as IndexedDBBroadcastChannelModule from 'o365.pwa.declaration.sw.indexedDBBroadcastChannel.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { TypedBroadcastChannel } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.TypedBroadcastChannel.d.ts')>("o365.pwa.modules.sw.TypedBroadcastChannel.ts");

    const broadcastChannelId = 'O365_PWA_INDEXED_DB_HANDLER_BROADCAST_CHANNEL';
    const broadcastChannel = new TypedBroadcastChannel<IndexedDBBroadcastChannelModule.EventData>(broadcastChannelId);

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.indexedDBBroadcastChannel.d.ts')>({ broadcastChannel });
})();
